import mockDocumentObj from './mockDocumentObj'

export default function getCookie(cname) {
  if (mockDocumentObj().cookie) {
    let name = cname + '='
    let splitCookies = mockDocumentObj().cookie.split(';')
    for (let i = 0; i < splitCookies.length; i++) {
      let cookie = splitCookies[i]
      while (cookie.charAt(0) == ' ') {
        cookie = cookie.substring(1)
      }
      if (cookie.indexOf(name) == 0) {
        return cookie.substring(name.length, cookie.length)
      }
    }
  }
  return ''
}
