import React, { useContext } from 'react'
import ReactModal from 'react-modal'
import { IconButton, css } from 'theme-ui'
import { FaTimes } from 'react-icons/fa'
import { ModalContext } from '@solid-ui-components/Modal'
import './styles.css'
import mockDocumentObj from '../utils/mockDocumentObj'

const styles = {
  modalContent: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 5,
    px: 4
  },
  close: {
    position: `absolute`,
    top: [3, null, -5],
    left: [2, null, null, -5],
    size: [`icon.sm`, null, `icon.md`],
    zIndex: 99,
    svg: {
      size: [`icon.sm`, null, `icon.md`]
    },
    ':hover': {
      color: `omegaLighter`
    }
  }
}

ReactModal.setAppElement('#___gatsby')

const Modal = ({ children, id, contentStyles, ...props }) => {
  const { activeModal, setActiveModal } = useContext(ModalContext)
  const restoreSession = () => {
    sessionStorage.removeItem("random")
    
  }
  
  const handelCloseModal = (value,buttonName) => {
    if (value === 'contact') {
      mockDocumentObj().cookie = 'isContactUs' + '=' + false + ';' + 'domain=eval.team;'
    }
    setActiveModal(null)
    if (buttonName !== 'closeButton') {
      restoreSession()
    }
  }

  return (
    <>
      <ReactModal
        id={id}
        isOpen={activeModal === id}
        
        closeTimeoutMS={300}
        onRequestClose={() => {handelCloseModal(activeModal)}}
        className='ModalPortalContent'
        overlayClassName='ModalPortalOverlay'
        shouldFocusAfterRender={false}
        css={css({ ...styles.modalContent, ...contentStyles })}
        {...props}
      >
        <IconButton
          onClick={() => {handelCloseModal(activeModal,"closeButton")}}
          sx={styles.close}
          aria-label='Close Modal'
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        >
          <FaTimes />
        </IconButton>
        {children}
      </ReactModal>
    </>
  )
}

export default Modal
